.navbar {
  /* display: flex;
  align-items: center;
  justify-content: space-between; */
  /* z-index: 100; */
  width: 100%;
  height: 10vh;
  background-color: transparent;
  display: flex;
  justify-content: space-between;
}

.navbar-sticky {
  background: white;
  position: fixed;
  top: 0;
  left: 0;
  /* box-shadow: 0px 4px 32px #eee; */
  animation: moveDown 0.5s ease-in-out;
  z-index: 100;
  width: -webkit-fill-available;
  width: -moz-available; 
}

.navbar--logo {
  width: 2rem;
  height: 2rem;
  margin-right: 0.5rem;
}

nav div{
  /* display: inline-block; */
}

.MenuLinks{
  font-size: 14px;
  color:#92919D;
  /* margin-right: 8px; */
  line-height: 76px;
  display: flex;
  align-items: center;
}

.MenuLinks a{
  margin-right: 44px;
}

.navbar .LanguageSelect{
  padding: 0;
}

.navbar .flag-select__btn{
  padding: 0 8px 0 4px;
  /* margin-top: -4px; */
  margin-right: -8px;
}

.flag-select__options{
  margin: 0;
}

.flag-select__option.has-label {
  padding: 4px 12px 8px 12px;
  line-height: initial;
}

@keyframes moveDown {
  from {
    transform: translateY(-5rem);
  }
  to {
    transform: translateY(0rem);
  }
}

.flag-select__options {
  margin-left: -25px;
}
