@font-face {
  font-family: "Muli-Bold";
  src: local("Muli-Bold"), url(./fonts/Muli/Muli-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "Muli-Black";
  src: local("Muli-Black"), url(./fonts/Muli/Muli-Black.ttf) format("truetype");
}

@font-face {
  font-family: "Muli-Regular";
  src: local("Muli-Regular"),
    url(./fonts/Muli/Muli-Regular.ttf) format("truetype");
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #39374d;
  overflow-x: hidden;
}

body {
  font-family: "Muli-Regular";
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

::-moz-selection {
  color: #fff;
  background: #ec4847;
}

::selection {
  color: #fff;
  background: #ec4847;
}

::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f4f7f8;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #9b9aa5;
  border-radius: 100px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #39374d;
}

.floating:hover {
  transition: 250ms ease-in;
  box-shadow: rgba(0, 0, 0, 0.086) 0px 3px 20px;
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
ul {
  margin: 0;
}

ul {
  list-style: none;
  padding: 0;
}

/* .SectionTitle{
  font-size: 32px;
  font-family: "Muli-Bold";
  margin: 56px auto 0;
} */

h1 {
  font-size: 32px;
  font-family: "Muli-Bold";
  margin: 80px auto 0;
  text-align: center;
  width: 100%;
  text-transform: uppercase;
}

h2 {
  font-size: 16px;
  font-family: "Muli-Bold";
  display: block;
  margin-bottom: 24px;
}

p {
  text-align: left;
  font-family: "Muli-Regular";
  font-size: 13px;
  display: block;
  line-height: 24px;
}

a {
  text-decoration: none;
  /* color: dodgerblue; */
  color: inherit;
  cursor: pointer;
}
a:visited {
  color: inherit;
}

.link{
  color: dodgerblue !important;
}

.AppPadding {
  padding: 0 10vw;
}

.whiteColor {
  color: #fff;
}

.opacity1 {
  opacity: 1;
}
.opacity055 {
  opacity: 0.55;
}

.LanguageSelect {
  /* background-color: firebrick; */
  padding: 0 8px 3px 0;
}

.active {
  color: #39374d;
  font-family: "Muli-Bold";
}

.CustomersLogoList {
  display: flex;
  justify-content: space-around;
  /* background: lightgreen; */
}
.CustomersLogoList li {
  /* background: lightcoral; */
  /* border-radius: 100px; */
  display: flex;
  overflow: hidden;
}
.CustomersLogoList li svg {
  width: 8vw;
  height: 8vw;
}

@media only screen and (max-width: 600px) {
  .CustomersLogoList li svg {
    width: 15vw;
    height: 15vw;
  }
}

footer {
  /* background: #1F2538; */
  background: #39374d;
  color: #fff;
}

footer a,
footer p {
  cursor: default;
}

footer .Logo {
  /*Defined in class*/
}

footer h2 {
  margin-bottom: 20px;
  text-transform: uppercase;
}

footer ul {
  /* background: yellowgreen; */
  min-width: 168px;
}

footer ul li {
  font-size: 13px;
  margin-bottom: 12px;
}

footer ul li a {
  opacity: 0.55;
}

footer ul li a:hover {
  opacity: 1;
  cursor: pointer;
  transition: 150ms ease-in;
}

.BtnLabor[disabled] {
  background-color: gray;
}

.BtnLabor[disabled]:hover {
  background-color: gray;
}

.BtnLabor:active {
  outline: dodgerblue;
}

.BtnLabor:hover {
  background-color: #b43434;
}

.BtnLaborBig {
  background-color: #ec4847;
  border: none;
  border-radius: 6px;
  font-size: 14px;
  /* line-height: 32px; */
  line-height: 36px;
  padding: 0 24px;
  /* padding: 0 28px; */
  color: #fff;
  font-weight: normal;
  outline: dodgerblue;
  cursor: pointer;
}

.BtnLabor {
  background-color: #ec4847;
  border: none;
  border-radius: 6px;
  font-size: 14px;
  line-height: 32px;
  padding: 0 24px;
  /* padding: 0 28px; */
  color: #fff;
  font-weight: normal;
  outline: dodgerblue;
  cursor: pointer;
}

.TabsGroup {
  text-align: center;
  margin: 8vh 0 6vh;
}

.BtnTab {
  background: transparent;
  border: none;
  font-size: 16px;
  outline: none;
  line-height: 24px;
  margin: 0 8px;
}
.BtnTab:not(.active) {
  color: rgb(57, 55, 77, 0.52);
}
.BtnTab:hover {
  cursor: pointer;
}

.BtnTab.active {
  background: #ec4847;
  border-radius: 20px;
  padding: 0 12px;
  color: #fff;
  font-size: 14px;
  font-weight: normal;
}

label {
  font-size: 14px;
  font-family: "Muli-Bold";
}

/* .st26{
  animation: Rotating infinite 20s linear;
}

@keyframes Rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

.Instagram,
.Facebook,
.Linkedin {
  margin-right: 12px;
  font-family: Muli-Bold;
  font-size: 14px;
  display: inline;
  padding: 4px 12px;
  transition: 250ms ease-in;
}
.Instagram:hover {
  background: radial-gradient(
    circle at 20% 157%,
    #fdf497 0%,
    #fdf497 5%,
    #fd5949 45%,
    #d6249f 60%,
    #285aeb 90%
  );
  color: #fff;
}
.Facebook:hover {
  background: #4267b2;
  color: #fff;
}
.Linkedin:hover {
  background: #0077b5;
  color: #fff;
}
.Instagram:hover,
.Facebook:hover,
.Linkedin:hover {
  transition: 250ms ease-out;
  /* padding: 8px; */
}

.ProjectsListContainer {
  display: block;
  width: inherit;
  text-align: center;
}

.ImgCard {
  width: 248px;
  min-width: 248px;
  height: 146px;
  background-size: cover;
  border-radius: 6px;
  box-shadow: #eee 0px 4px 6px;
  overflow: hidden;
  margin-top: 4vh;
  display: inline-block;
  margin: 4vh 3vw;
  background-position: center;
}
.ImgCard .HoverView {
  background: rgb(236, 72, 71, 0.72);
  height: 100%;
  width: 100%;
  border-radius: 6px;
  /* display: flex; */
  align-items: center;
  justify-content: center;
  display: none;
  transition: 2s ease-in;
}
.ImgCard .HoverView h4 {
  margin-bottom: 12px;
  cursor: default;
}
.ImgCard .HoverView * {
  color: #fff;
}

@media only screen and (max-width: 960px) {
  .ImgCard {
    position: relative;
  }
  .ImgCard .ImgCardLabel h4 {
    font-weight: normal;
  }
  .ImgCard .ImgCardLabel {
    display: flex;
    background: rgba(236, 72, 71, 0.8);
    color: #fff;
    height: 28px;
    font-size: 13px;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    bottom: 0;
  }
}
